<template>
  <section>
    <v-form ref="form" v-model="valid" lazy-validation class="d-flex flex-column mb-4">

      <div class="d-flex justify-content-between" @click="openFileBottomSheet">
        <span class="my-1">{{ $t('Select image / file') }}</span>
        <v-btn color="primary" class="" rounded>{{ $t('Choice') }}</v-btn>
      </div>

      <div id="previewContainer" class="imagePreviewWrapper d-none">

        <img v-if="selectedFile == 'IMAGE'" :src="pickedfile" class="img-preview" />

        <div v-if="selectedFile == 'FILE'" class="d-flex flex-column align-items-center justify-content-center">
          <iconly type="bulk" name="document" color="#8C489F" size="250" />
          <span>{{ fileName }}.{{ fileExtension }}</span>
        </div>

      </div>

      <span class="my-1 d-block">{{ $t('Title') }}</span>
      <v-text-field outlined single-line v-model="title" type="text" :rules="titleRules" required counter="128" />


      <span class="my-1 d-block">{{ $t('Description') }}</span>
      <v-textarea outlined v-model="description" type="text" counter="600" :rules="descriptionRules" required />

      <div class="mt-1 text-center">
        <v-btn color="primary" large class="col-12" @click="validate()" :loading="submit_loading"
          :disabled="submit_loading">
          {{ $t('Submit') }}
        </v-btn>
      </div>
    </v-form>

    <vue-bottom-sheet ref="fileBottomSheet">
      <section class="d-flex flex-column align-items-center justify-content-center">
        <v-list class="w-100 pt-0 mt-auto" light shaped>
          <span class="text-center mb-1 d-block f14 font-weight-bold">{{ $t('File selection') }}</span>
          <input ref="imageInput" type="file" @input="pickImage" accept="image/*" hidden>
          <!-- "doc", "docx", "xls", "xlsx", "pdf", "txt" -->
          <input ref="fileInput" type="file" @input="pickFile" accept=".doc,.docx,.xls,.xlsx,.pdf,.txt" hidden>
          <v-list-item ripple @click="selectFile">
            <v-list-item-icon class="my-0 align-self-center">
              <v-sheet color="#F1F0FF" elevation="0"
                class="d-flex align-items-center justify-content-center rounded-lg p-2">
                <iconly type="bulk" name="document" color="#8C489F" />
              </v-sheet>
            </v-list-item-icon>
            <v-list-item-title tag="span" class="f14">
              <span>{{ $t('Select file') }}</span>
            </v-list-item-title>
          </v-list-item>

          <v-list-item ripple @click="selectImage">
            <v-list-item-icon class="my-0 align-self-center">
              <v-sheet color="#fff3e1" elevation="0"
                class="d-flex align-items-center justify-content-center rounded-lg p-2">
                <iconly type="bulk" name="image" color="#f8b758" />
              </v-sheet>
            </v-list-item-icon>
            <v-list-item-title tag="span" class="f14">
              <span>{{ $t('Select image') }}</span>
            </v-list-item-title>
          </v-list-item>

          <v-btn block color="primary" class="my-2" rounded>{{ $t('Close') }}
          </v-btn>
        </v-list>
      </section>
    </vue-bottom-sheet>

  </section>
</template>

<script>
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();
import * as blobUtil from 'blob-util'

export default {
  data() {
    return {
      submit_loading: false,
      typeLoading: true,
      valid: false,

      title: "",
      description: "",

      pickedfile: null,

      fileName: null,
      fileExtension: null,

      selectedFile: null,

      uploadedPicUrl: "",
      uploadedThumbnailPicUrl: "",


      titleRules: [
        v => !!v,
        v => (v && v.length <= 128) || this.$t("The maximum length is 128 characters")
      ],

      descriptionRules: [
        v => !!v,
        v => (v && v.length <= 600) || this.$t("The maximum length is 600 characters")
      ],

    }
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    openFileBottomSheet() {
      this.$refs.fileBottomSheet.open();
    },
    closeFileBottomSheet() {
      this.$refs.fileBottomSheet.close();
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.submitForm();
      }
    },
    selectImage() {
      this.selectedFile = 'IMAGE';
      this.$refs.imageInput.click()
    },
    selectFile() {
      this.selectedFile = 'FILE';
      this.$refs.fileInput.click()
    },
    pickImage() {
      let input = this.$refs.imageInput
      let file = input.files
      if (file && file[0]) {

        const lastDot = file[0].name.lastIndexOf('.');
        this.fileName = file[0].name.substring(0, lastDot);
        this.fileExtension = file[0].name.substring(lastDot + 1);

        let reader = new FileReader
        reader.onload = e => {
          this.pickedfile = e.target.result
        }
        reader.readAsDataURL(file[0])

        // $("#previewContainer").removeClass("d-none")
        // $("#previewContainer").addClass("d-block")

        this.closeFileBottomSheet()
        this.$emit('input', file[0])
      }
    },
    pickFile() {
      let input = this.$refs.fileInput
      let file = input.files
      if (file && file[0]) {

        const lastDot = file[0].name.lastIndexOf('.');
        this.fileName = file[0].name.substring(0, lastDot);
        this.fileExtension = file[0].name.substring(lastDot + 1);

        let reader = new FileReader
        reader.onload = e => {
          this.pickedfile = e.target.result
        }
        reader.readAsDataURL(file[0])

        // $("#previewContainer").removeClass("d-none")
        // $("#previewContainer").addClass("d-block")

        this.closeFileBottomSheet()
        this.$emit('input', file[0])
      }
    },

    submitForm() {
      if (!this.selectedFile || !this.pickedfile) {
        this.$swal({
          icon: "warning",
          text: this.$t("No file selected. Please select an file"),
          confirmButtonText: this.$t('OK'),
        });

        return;
      }
      this.submit_loading = true;
      this.upload();
    },

    upload() {

      let formData = new FormData();

        var blob = blobUtil.dataURLToBlob(this.pickedfile);
        formData.append("files", blob, this.fileName + "." + this.fileExtension);

      apiService.addNewExRequest(formData, this.title, this.description, "EXERCISE")
        .then(() => {
          this.submit_loading = false;
          this.$swal({
            icon: "success",
            text: this.$t('The content was added successfully'),
            confirmButtonText: this.$t('OK'),
          })
            .then((result) => {
              if (result.isConfirmed) {
                this.$router.go(-1);
              }
            });
        })
        .catch((err) => {
          this.submit_loading = false;
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          });
        });
    },
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);
  },
  created() {
  }
}
</script>
<style scoped lang="scss">
.imagePreviewWrapper {
  width: 300px;
  height: 300px;
  display: block;
  margin: 0 auto 30px;
  background-size: cover;
  background-position: center center;
}

.img-preview {
  width: 300px;
  height: 300px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}
</style>